/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useCallback} from "react";
import {useParams} from "react-router-dom";
import {useSidebarItems} from "../../hooks";
import {ErrorPage, PageHeading, PageTemplate} from "../../layout";
import {ActivityIndicator} from "../../activity-indicator";
import {ClientEntityProvider} from "../context";
import {BackendError} from "@common-core/runtime-js/backend";
import {Endpoints} from "../../runtime";
import {useTranslation} from "react-i18next";
import {useAnalytics} from "@common-core/react-analytics";
import {ClientEntity} from "@common-core/coat-operational-hierarchy-appsync-model";
import {LookupClientEntityById} from "./backend";
import {useQuery} from "@apollo/client";
import {EntityResponse} from "../backend";
import {HashTabs} from "../../hash-tabs";
import {ClientHierarchyOverviewTab} from "./overview-tab";
import {CreateOperationalEntityButton} from "./CreateOperationalEntityButton";
import {Protected} from "../../access";
import {ClientHierarchyHierarchyTab} from "./hierarchy-tab";

interface RouteParams {
  id: string;
}

/**
 * The Page concerns itself with routing information and extracts
 * what it needs to prepare the {@link ClientOverviewTab} component
 * for display
 *
 * @constructor
 */
export const ClientHierarchyPage: FC = () => {
  const sidebarItems = useSidebarItems();
  const {t} = useTranslation();
  const {productEvent} = useAnalytics();
  const {id} = useParams<RouteParams>();
  const {loading, error, data} = useQuery<EntityResponse<ClientEntity>>(
    LookupClientEntityById,
    {
      variables: {id},
      fetchPolicy: "no-cache",
      context: {
        endpoint: Endpoints.APPSYNC
      }
    }
  );

  const translate = useCallback(
    (tab: string) => {
      return tab === "hierarchy" || tab === "overview"
        ? t(`entity-detail.${tab}`, {lng: "en"})
        : t(`entity-tabs.embedded-components.${tab}`, {lng: "en"});
    },
    [t]
  );

  if (error) {
    return <ErrorPage error={error} logo={false} sidebarItems={sidebarItems} />;
  }

  if (loading) {
    return (
      <PageTemplate
        banner={
          <PageHeading
            title={t("client-hierarchy.banner.title")}
            summary={t("client-hierarchy.banner.summary")}
          />
        }
        id={"client-entity-page"}
        title={"Client Entity Page"}
        sidebarItems={sidebarItems}>
        <ActivityIndicator />
      </PageTemplate>
    );
  }

  if (!data?.entity) {
    return (
      <ErrorPage
        error={new BackendError("Entity not found", 404)}
        logo={false}
        sidebarItems={sidebarItems}
      />
    );
  }

  return (
    <PageTemplate
      banner={
        <PageHeading
          title={t("client-hierarchy.banner.title")}
          summary={t("client-hierarchy.banner.summary")}
        />
      }
      id={"client-entity-page"}
      title={"Client Entity Page"}
      sidebarItems={sidebarItems}>
      <ClientEntityProvider entity={data.entity}>
        <div className={"entity-detail"} data-testid={"entity-detail"}>
          <header className={"entity-detail-header"}>
            <h2 className={"entity-business-name"}>{data.entity.custName}</h2>
            <Protected
              permissions={[
                {resource: "operational-entity", action: "create"}
              ]}>
              <CreateOperationalEntityButton />
            </Protected>
          </header>

          <HashTabs
            id={"entity-detail-tabs"}
            initial={"overview"}
            onTabActivated={event => {
              productEvent({
                name: `entity_detail_tab_clicked`,
                properties: {
                  location: "Client Hierarchy Details Overview",
                  value: `${translate(event.tab)}`,
                  result: "Tab Displayed"
                }
              });
            }}>
            <ClientHierarchyOverviewTab
              hash={"overview"}
              label={t("entity-tabs.client-overview")}
            />
            <ClientHierarchyHierarchyTab
              hash={"hierarchy"}
              label={t("entity-tabs.hierarchy")}
            />
          </HashTabs>
        </div>
      </ClientEntityProvider>
    </PageTemplate>
  );
};
