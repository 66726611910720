import {FC, useState} from "react";
import {OnCompleteCallback} from "../../../utils";
import {ModalWithAlert, useAlert} from "../../../alerting";
import {useTranslation} from "react-i18next";
import {useOperationalEntity} from "../../context";
import {useLazyQuery, useMutation} from "@apollo/client";
import {
  EntityResponse,
  LookupOperationalEntity,
  UpdateOperationalEntityBusinessStatus
} from "../../backend";
import {
  OperationalEntity,
  UpdateBusinessStatusInput,
  UpdateResponse
} from "@common-core/coat-operational-hierarchy-appsync-model";
import {Endpoints} from "../../../runtime";
import {SelectInput} from "@interstate/components/SelectInput";
import {DealerStatus} from "../../model";
import {assertEntityPresent} from "../../boids/transforms";

export const EditStatusModal: FC<{
  id: string;
  currentStatus: string;
  onComplete: OnCompleteCallback;
}> = ({id, currentStatus, onComplete}) => {
  const {t} = useTranslation();
  const selectInputLabel = t("modal.text-input.edit-status");
  const {alert, clearAlert, createErrorAlert} = useAlert();
  const [working, setWorking] = useState(false);
  const {entity, setEntity} = useOperationalEntity();
  const [status, setStatus] = useState(currentStatus);

  const [lookUpOperationalEntity] = useLazyQuery<
    EntityResponse<OperationalEntity>
  >(LookupOperationalEntity, {
    context: {
      endpoint: Endpoints.APPSYNC
    },
    fetchPolicy: "network-only"
  });

  const [updateStatus] = useMutation<UpdateResponse>(
    UpdateOperationalEntityBusinessStatus,
    {
      context: {
        endpoint: Endpoints.APPSYNC
      }
    }
  );

  const refreshEntity = async (): Promise<void> => {
    return await lookUpOperationalEntity({variables: {id: entity.id}})
      .then(assertEntityPresent)
      .then(setEntity);
  };

  const onSelect = (event: any) => {
    setStatus(event.target.value);
  };

  const saveChanges = async () => {
    setWorking(true);
    clearAlert();
    const statusInput: UpdateBusinessStatusInput = {
      entityId: id,
      status
    };
    await updateStatus({
      variables: {statusInput}
    })
      .then(refreshEntity)
      // If the update call returns an error, give the user the opportunity to try again
      .then(() => onComplete(true))
      .catch(createErrorAlert)
      .finally(() => setWorking(false));
  };

  const cancelChanges = () => {
    onComplete(true);
  };

  return (
    <ModalWithAlert
      id={"edit-status-modal"}
      data-testid={"edit-status-modal"}
      show={true}
      size={"large"}
      header={t("modal.header.edit-status")}
      alert={alert}
      footer={{
        primary: {
          action: saveChanges,
          label: t("common-actions.save-changes"),
          isLoading: working
        },
        options: {
          action: cancelChanges,
          label: t("common-actions.cancel"),
          isLoading: working,
          buttonStyle: "tertiary"
        }
      }}
      onHide={cancelChanges}>
      <SelectInput
        id={"edit-business-status-select-input"}
        data-testid={"edit-business-status"}
        label={selectInputLabel}
        name={"update-business-status"}
        value={status}
        onChange={onSelect}
        options={[
          {label: "In Business", value: DealerStatus.IN_BUSINESS},
          {label: "Out of Business", value: DealerStatus.OUT_OF_BUSINESS}
        ]}
        displayDeselectOption={false}
      />
    </ModalWithAlert>
  );
};
