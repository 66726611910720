import {FC, useState} from "react";
import {TextInput} from "@interstate/components/TextInput";
import {useOperationalEntity} from "../../context";
import {assertEntityPresent} from "../../boids/transforms";
import {useLazyQuery, useMutation} from "@apollo/client";
import {
  EntityResponse,
  LookupOperationalEntity,
  UpdateOperationalEntitySources
} from "../../backend";
import {
  OperationalEntity,
  Source,
  UpdateResponse,
  UpdateSourcesInput
} from "@common-core/coat-operational-hierarchy-appsync-model";
import {Endpoints} from "../../../runtime";
import {useTranslation} from "react-i18next";
import {ErrorHandler, OnCompleteCallback} from "../../../utils";
import {ModalWithAlert, useAlert} from "../../../alerting";
import "./EditSourcesModal.scss";
export const EditSourcesModal: FC<{
  id: string;
  initalSources: Source[];
  onComplete: OnCompleteCallback;
}> = ({id, initalSources, onComplete}) => {
  const {t} = useTranslation();
  const {alert, setAlert, createErrorAlert} = useAlert();
  const [inputErrorMessage, setInputErrorMessage] = useState<string>();
  const [working, setWorking] = useState(false);
  const [sources, setSources] = useState(initalSources);
  const {entity, setEntity} = useOperationalEntity();

  const [lookUpOperationalEntity] = useLazyQuery<
    EntityResponse<OperationalEntity>
  >(LookupOperationalEntity, {
    context: {
      endpoint: Endpoints.APPSYNC
    },
    fetchPolicy: "network-only"
  });

  const [updateSources] = useMutation<UpdateResponse>(
    UpdateOperationalEntitySources,
    {
      context: {
        endpoint: Endpoints.APPSYNC
      }
    }
  );

  const clearErrors = (): void => {
    setAlert(undefined);
    setInputErrorMessage(undefined);
  };

  const handleError: ErrorHandler = (error: any) => {
    // TODO: We need a generic mechanism to indicate validation-related errors along with a reason-code
    error.message.includes("parent id:")
      ? setInputErrorMessage(error.message)
      : createErrorAlert(error);
  };

  const refreshEntity = async (): Promise<void> => {
    return await lookUpOperationalEntity({variables: {id: entity.id}})
      .then(assertEntityPresent)
      .then(setEntity);
  };

  const saveChanges = async () => {
    setWorking(true);
    clearErrors();
    const sourcesInput: UpdateSourcesInput = {entityId: id, sources};
    await updateSources({
      variables: {sourcesInput}
    })
      .then(refreshEntity)
      // If the update call returns an error, give the user the opportunity to try again
      .then(() => onComplete(true))
      .catch(handleError)
      .finally(() => setWorking(false));
  };

  const cancelChanges = () => {
    onComplete(true);
  };

  const onInputChange = (event: any) => {
    event.target?.value.trim() === ""
      ? setSources([])
      : setSources([
          {sourceId: event.target?.value.trim(), sourceType: "CACM"}
        ]);
  };

  return (
    <ModalWithAlert
      id={"edit-source-modal"}
      data-testid={"edit-source-modal"}
      show={true}
      size={"large"}
      header={t("modal.header.edit-source")}
      alert={alert}
      footer={{
        primary: {
          action: saveChanges,
          label: t("common-actions.save-changes"),
          isLoading: working
        },
        options: {
          action: cancelChanges,
          label: t("common-actions.cancel"),
          isLoading: working,
          buttonStyle: "tertiary"
        }
      }}
      onHide={cancelChanges}>
      <TextInput
        id={"source-text-input"}
        data-testid={"source-text-input"}
        name={"parent"}
        label={`${t("modal.text-input.edit-source")}`}
        value={sources[0] ? sources[0].sourceId : ""}
        onChange={onInputChange}
        onKeyDown={async (event: any) => {
          if (event.key === "Enter") {
            await saveChanges();
          }
        }}
        clearButton={!!sources[0]}
        hasError={!!inputErrorMessage}
        errorMessage={inputErrorMessage}
      />
    </ModalWithAlert>
  );
};
