import {FC} from "react";
import "./ClientHierarchyDetailsColumn.scss";
import {useClientEntity} from "../../context";
import {Address, DetailsColumnHeader, Oems} from "../../overview";
import {TabColumn} from "../../../tab-content";
import {useTranslation} from "react-i18next";
import {ClientHierarchyBusinessInformation} from "./ClientHierarchyBusinessInformation";
import {
  ClientAddress,
  Oem
} from "@common-core/coat-operational-hierarchy-appsync-model";
import {ClientHierarchyStatusBar} from "./ClientHierarchyStatusBar";

export const ClientHierarchyDetailsColumn: FC = () => {
  const {
    entity: {
      caId,
      custLegalName,
      clientNameplates,
      custStatus,
      addresses,
      custPhoneNumber,
      custParentId
    }
  } = useClientEntity();

  const {t} = useTranslation();
  // Find the first address marked as Primary:

  let oems: Oem[] = [];

  let addressToDisplay: ClientAddress | undefined = undefined;

  // Pick the first address flagged as primary, or if there are none flagged as such, just pick the first address from the list.
  if (addresses?.length) {
    addressToDisplay =
      addresses.find(address => address.primaryFlag?.toUpperCase() === "Y") ||
      addresses[0];
  }

  if (clientNameplates) {
    oems = clientNameplates.map(nameplate => ({
      id: nameplate.oemId,
      abbreviation: nameplate.oemAbbreviation,
      locationId: nameplate.oemLocationId,
      nameplateDescription: nameplate.nameplateDesc
    }));
  }

  return (
    <TabColumn
      name={"details-column"}
      header={
        <DetailsColumnHeader
          label={t("client-entity-details.details-card-label")}
          id={caId}
        />
      }>
      <ClientHierarchyStatusBar id={caId} status={custStatus} />
      <ClientHierarchyBusinessInformation
        caId={caId}
        custLegalName={custLegalName}
        custParentId={custParentId}
      />
      <Address
        id={caId}
        streetAddressLine1={addressToDisplay?.addrLine1}
        streetAddressLine2={addressToDisplay?.addrLine2}
        city={addressToDisplay?.city}
        state={addressToDisplay?.provinceCd}
        country={addressToDisplay?.countryCd}
        zip={addressToDisplay?.postalCd}
        telecomNumbers={(custPhoneNumber ?? "").split(",")}
      />
      <Oems id={caId} oems={oems} />
    </TabColumn>
  );
};
