import {AuthState, OktaAuthCoreInterface} from "@okta/okta-auth-js";
import {LDClient} from "launchdarkly-js-client-sdk";
import {Subject, anonymous} from "../access";
import {toLDContext} from "../features";

export async function transformAuthState(
  oktaAuth: OktaAuthCoreInterface,
  authState: AuthState,
  ldClient?: LDClient
): Promise<AuthState> {
  // Subject is unauthenticated
  if (!authState.isAuthenticated || !authState.accessToken) {
    authState.subject = anonymous;
    return authState;
  }
  authState.subject = {
    platformId: authState.accessToken.claims.sub,
    name: authState.idToken?.claims.name,
    username: authState.idToken?.claims.preferred_username
  } as Subject;
  await ldClient?.identify(toLDContext(authState.subject as Subject));
  return authState;
}
