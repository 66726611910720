import {FC, useEffect, useCallback, useState, MouseEventHandler} from "react";
import {useTranslation} from "react-i18next";
import {useFlags} from "launchdarkly-react-client-sdk";
import {Box} from "@interstate/components/Box";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon
} from "@interstate/components/Icons";
import {Button} from "@interstate/components/Button";
import {Skeleton} from "@interstate/components/Skeleton";
import {IconType} from "../lib/interstate";
import {Permission, useAuthorization} from "../access";
import {useStickyState} from "../hooks";
import "./Sidebar.scss";

export type AuthorizationCheckDecision = "show" | "hide";

export interface SidebarItem {
  id: string;
  active?: boolean;
  featureFlag?: string;
  permissions?: Permission[];
  whenAuthorized?: AuthorizationCheckDecision;
  whenUnauthorized?: AuthorizationCheckDecision;
  labelKey: string;
  icon: IconType;
  onClick: MouseEventHandler;
}

export interface SidebarProps {
  expanded?: boolean;
  items: SidebarItem[];
}

function itemIsDefined(item?: SidebarItem): item is SidebarItem {
  return item !== undefined;
}

export const Sidebar: FC<SidebarProps> = ({expanded = false, items}) => {
  const [visibleItems, setVisibleItems] = useState<SidebarItem[]>(() => {
    // Initialize the sidebar items with a single "skeleton" item as a placeholder
    // until the visible items are resolved. Blocking out a skeleton item prevents
    // the UI elements from shifting around once the items to render become available
    return [
      {
        id: "skeleton",
        icon: EllipsisHorizontalIcon,
        labelKey: "navigation.skeleton",
        active: false,
        onClick: () => {}
      } satisfies SidebarItem
    ];
  });
  const flags = useFlags();
  const granted = useAuthorization();
  const {t} = useTranslation();
  const [collapsed, setCollapsed] = useStickyState<boolean>(
    !expanded,
    "coat2.sidebar.collapsed"
  );

  const classes = collapsed
    ? "coat-sidebar--collapsed"
    : "coat-sidebar--expanded";

  /**
   * This function collects the authorization checks for items protected by permissions.
   *
   * <ul>
   *   <li>When permissions are defined for the item, the authorization check decision is used to
   *       determine visibility based on the outcome of the check.</li>
   *   <li>When permissions are not defined for the item, it is treated as implicitly permitted.</li>
   * </ul>
   */
  const collectPermittedItems = useCallback(
    (): Promise<SidebarItem | undefined>[] =>
      items.map(item => {
        return item.permissions
          ? granted(item.permissions).then(authorized =>
              authorized
                ? (item.whenAuthorized || "show") === "show"
                  ? item
                  : undefined
                : (item.whenUnauthorized || "hide") === "show"
                  ? item
                  : undefined
            )
          : Promise.resolve(item);
      }),
    [items, granted]
  );

  /**
   * This array filter function is used to filter any items that have a feature flag defined and enabled;
   * When no feature flag is defined, it is treated as implicitly enabled.
   */
  const itemIsEnabled = useCallback(
    (item: SidebarItem): boolean =>
      item.featureFlag ? flags[item.featureFlag] : true,
    [flags]
  );

  /**
   * This effect is responsible for determining the list of visible sidebar
   * items. It must be performed out-of-band due to the asynchronous nature
   * of the permission evaluation operation.
   */
  useEffect(() => {
    // Wait for all the authorization check promises to settle, rejecting immediately if any individual promise rejects
    Promise.all(collectPermittedItems())
      // Once all promises are settled, filter out the un-permitted (undefined) items
      // and perform FF checks on the remaining
      .then(items => items.filter(itemIsDefined).filter(itemIsEnabled))
      .then(setVisibleItems);
  }, [collectPermittedItems, itemIsEnabled]);

  return (
    <Box
      component={"nav"}
      id={"coat-sidebar"}
      data-testid={"coat-sidebar"}
      className={classes}>
      <Box component={"ul"} className={"coat-sidebar-items"}>
        {visibleItems.map(item => {
          const itemClasses = `coat-sidebar-item ${
            item.active
              ? "coat-sidebar-item--active"
              : "coat-sidebar-item--inactive"
          }`;
          const linkId = `coat-sidebar-item-link-${item.id}`;
          const Icon = item.icon;
          const label = t(item.labelKey, "???");
          const skeleton = item.id === "skeleton";
          return (
            <li key={item.id} className={itemClasses} onClick={item.onClick}>
              <Button
                id={linkId}
                data-testid={linkId}
                className={"coat-sidebar-item-link"}
                buttonStyle={"tertiary"}
                block={true}
                startIcon={<Icon title={label} />}>
                {!collapsed && skeleton && (
                  <Skeleton
                    data-testid={`coat-sidebar-item-label-${item.id}`}
                    variant={{type: "text"}}
                    sx={theme => ({
                      width: 103.2
                    })}
                  />
                )}
                {!collapsed && !skeleton && (
                  <span className={"coat-sidebar-item-label"}>{label}</span>
                )}
              </Button>
            </li>
          );
        })}
      </Box>
      <Button
        id={"coat-sidebar-control"}
        data-testid={"coat-sidebar-control"}
        size={"small"}
        buttonStyle={"secondary"}
        startIcon={
          collapsed ? (
            <ChevronRightIcon
              viewBox={"-3 -2 20 20"}
              title={t("navigation.expand", "???") || "???"}
            />
          ) : (
            <ChevronLeftIcon
              viewBox={"-1 -2 20 20"}
              title={t("navigation.collapse", "???") || "???"}
            />
          )
        }
        onClick={() => setCollapsed(value => !value)}
      />
    </Box>
  );
};
