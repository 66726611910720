import {FC} from "react";
import {useLocation} from "react-router-dom";

import {useTranslation} from "react-i18next";
import {useSidebarItems} from "../../../hooks";
import {PageHeading, PageTemplate} from "../../../layout";
import {BackToButton, Pages} from "../../../back-buttons";
import {SearchPageState} from "../../../search";
import {OperationalSearch} from "./OperationalSearch";

/**
 * The {@link OperationalSearchPage} component provides the page layout plugging
 * in components where needed. It also concerns itself with routing
 * information and extracts what it needs to prepare the {@link OldCommonOrgEntitySearch}
 * component for display.
 *
 * @constructor
 */
// TODO: Split this page into two pages, using feature flags in a selector.
// Then add a banner to OE Search so it's clear what data is retrieved
// Split OldCommonOrgEntitySearch into two components-- no more entitySourceType
export const OperationalSearchPage: FC = () => {
  const location = useLocation<SearchPageState>();
  const query = location.search.match(/query=/i)
    ? new URLSearchParams(location.search).get("query") || ""
    : location.state?.query || "";
  const sidebarItems = useSidebarItems("search");
  const {t} = useTranslation();

  return (
    <PageTemplate
      banner={
        <PageHeading
          title={t("operational-page.banner.title")}
          summary={t("operational-page.banner.summary")}
        />
      }
      id={"search-page"}
      title={"Search Page"}
      breadcrumbs={<BackToButton page={Pages.Home} />}
      sidebarItems={sidebarItems}>
      <OperationalSearch query={query} />
    </PageTemplate>
  );
};
