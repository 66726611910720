import {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAnalytics} from "@common-core/react-analytics";
import {
  InformationCircleIcon,
  MagnifyingGlassIcon
} from "@interstate/components/Icons";
import {Button} from "@interstate/components/Button";
import {SlideOut} from "@interstate/components/SlideOut";
import {TextInput} from "@interstate/components/TextInput";
import {Tooltip} from "@interstate/components/Tooltip";
import {empty} from "../../utils";
import {SearchAssistancePanel} from "./SearchAssistancePanel";
import "./SearchBar.scss";

function normalize(query = ""): string {
  // prettier-ignore
  return query
      .replace(/ and /gi, " AND ")
      .replace(/ or /gi, " OR ")
      .replace(/ not /gi, " NOT ");
}

export type QueryListener = (query: string) => void;
export type SearchListener = (event: any, query: string) => void;

export interface SearchBarProps {
  id: string;
  query: string;
  onQueryChange?: QueryListener;
  onQueryCleared?: () => void;
  onSearch?: SearchListener;
  analyticsLocation: string;
  displayAssistanceButton?: boolean;
}

export const SearchBar: FC<SearchBarProps> = ({
  id,
  query,
  analyticsLocation,
  onQueryChange = () => {},
  onQueryCleared = () => {},
  onSearch = () => {},
  displayAssistanceButton = true
}) => {
  const {t} = useTranslation();
  const {productEvent} = useAnalytics();
  const [assistanceVisible, setAssistanceVisible] = useState<boolean>(false);
  // normalize the incoming query value that will be rendered
  const value = normalize(query);
  const [stroke, setStroke] = useState<string>(() =>
    value ? "white" : "currentColor"
  );
  const placeholder = t("entity-search.placeholder");

  const onInputChange = (event: any): void => {
    // normalize the raw text entered into the text input before emitting it to the listener
    const current = normalize(event.target?.value);
    onQueryChange(current);
    if (empty(current)) {
      onQueryCleared();
      setStroke("currentColor");
    } else {
      setStroke("white");
    }
  };

  const onEnter = (event: any): void => {
    // perform any final query validation before emitting it to the listener
    productEvent({
      name: "search_clicked",
      properties: {
        location: `${t(`analytics.location.${analyticsLocation}`)} Search`,
        value: query,
        result: `${t(
          `analytics.location.${analyticsLocation}`
        )} Search Results Displayed`
      }
    });
    onSearch(event, value.trim());
  };

  return (
    <div
      id={`${id}-wrapper`}
      data-testid={`${id}-wrapper`}
      className={"search-bar"}>
      <TextInput
        id={id}
        data-testid={id}
        className={"search-text-box"}
        label={t("entity-search.search") || "???"}
        name="search"
        inputPrefix={
          <span className={"syntax-btn"}>
            {displayAssistanceButton && (
              <Button
                id="assistance-button"
                data-testid="assistance-button"
                size={"large"}
                icon={
                  <Tooltip
                    id={"assistance-tooltip"}
                    size={"small"}
                    position={"right"}
                    toolTipContent={t("entity-search.query-syntax-tooltip")}>
                    <InformationCircleIcon
                      id={"assistance-icon"}
                      data-testid={"assistance-icon"}
                    />
                  </Tooltip>
                }
                onClick={() => {
                  productEvent({
                    name: "search_assistance_clicked",
                    properties: {
                      location: `${analyticsLocation}`,
                      result: "Search Assistance Displayed"
                    }
                  });
                  setAssistanceVisible(true);
                }}
              />
            )}
          </span>
        }
        inputSuffix={
          <Button
            id="search-button"
            data-testid="search-button"
            className={"search-button"}
            size={"medium"}
            icon={<MagnifyingGlassIcon stroke={stroke} />}
            onClick={onEnter}
            disabled={empty(value)}
          />
        }
        autoComplete="quickSearch"
        autoFocus={true}
        placeholder={placeholder}
        value={value}
        onChange={event => onInputChange(event)}
        onKeyDown={(event: any) => {
          if (event.key === "Enter" && !empty(event?.target?.value)) {
            onEnter(event);
          }
        }}
        clearButton={!empty(value)}
      />
      <SlideOut
        id="search-assistance-drawer"
        data-testid="search-assistance-drawer"
        show={assistanceVisible}
        panelWidth={"75%"}
        position={"left"}
        header={t("entity-search.assistance.header")}
        onHide={() => setAssistanceVisible(false)}>
        <SearchAssistancePanel close={() => setAssistanceVisible(false)} />
      </SlideOut>
    </div>
  );
};
