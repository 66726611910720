/* eslint-disable no-console */
import {Dispatch, SetStateAction, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {ErrorHandler} from "../utils";
import {AlertDescriptor} from "./AlertDescriptor";

export interface AlertHookValue {
  alert: AlertDescriptor | undefined;
  setAlert: Dispatch<SetStateAction<AlertDescriptor | undefined>>;
  clearAlert: () => void;
  createErrorAlert: ErrorHandler;
}

export function useAlert(): AlertHookValue {
  const {t} = useTranslation();
  const [alert, setAlert] = useState<AlertDescriptor>();
  return useMemo<AlertHookValue>(
    () => ({
      alert,
      setAlert,
      clearAlert: () => setAlert(undefined),
      createErrorAlert: (error: any) => {
        console.error(error);
        setAlert({
          type: "error",
          title: t("toast.backend-error", "???"),
          content: () => (
            <span className={"alert-message"}>{error.message}</span>
          )
        });
      }
    }),
    [alert, setAlert, t]
  );
}
