import {FC, PropsWithChildren, useMemo} from "react";
import {useEnvironment} from "@common-core/react-runtime/context";
import {useOktaAuth} from "@okta/okta-react";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject
} from "@apollo/client";
import {removeTypenameFromVariables} from "@apollo/client/link/remove-typename";
import {useFlags} from "launchdarkly-react-client-sdk";
import {features} from "../features";
import {Endpoints, Environment} from "../runtime";
import {v4 as uuidv4} from "uuid";

/**
 * {@link GraphqlClientProvider} provides the Apollo Client to all downstream components
 * allowing them to call backend endpoints. Endpoints are provided the environment and
 * limited to those declared in {@link Endpoints}. The client is initialized with a terminating
 * HttpLink which checks what endpoint was sent in the operation's context and chooses the correct
 * url based on that endpoint.
 * See https://www.apollographql.com/docs/react/api/link/introduction for link documentation
 * and https://www.apollographql.com/docs/react/api/core/ApolloClient for client documentation.
 *
 * New data sources (read: endpoints) will need to be added to {@link Endpoints}, the switch statement below,
 * {@link environments}, and {@link Backends}.
 * @param children
 * @constructor
 */
export const GraphqlClientProvider: FC<PropsWithChildren> = ({children}) => {
  const environment = useEnvironment<Environment>();
  const {authState} = useOktaAuth();
  const flags = useFlags();
  const connectToDevTools = flags[features.ops.apolloClientConnectToDevTools];

  const setCorrelationIdHeader = new ApolloLink((operation, forward) => {
    operation.setContext(() => ({
      headers: {"X-CoxAuto-Correlation-Id": uuidv4()}
    }));
    return forward(operation);
  });

  const client = useMemo<ApolloClient<NormalizedCacheObject>>(() => {
    const switchEndpoint = (endpoint: Endpoints) => {
      switch (endpoint) {
        case Endpoints.BACKEND: {
          return `${environment.backend.baseUri}/graphql`;
        }
        case Endpoints.APPSYNC: {
          return `${environment.appsync}`;
        }
        default: {
          return `${environment.backend.baseUri}/graphql`;
        }
      }
    };

    return new ApolloClient({
      link: from([
        removeTypenameFromVariables(),
        setCorrelationIdHeader,
        new HttpLink({
          uri: operation => {
            const endpoint = operation.getContext().endpoint;
            return switchEndpoint(endpoint);
          },
          headers: {
            "authorization": `Bearer ${authState?.accessToken?.accessToken}`,
            "X-Bridge-Id": `Bearer ${authState?.idToken?.idToken}`
          }
        })
      ]),
      cache: new InMemoryCache(),
      connectToDevTools
    });
  }, [environment, authState, connectToDevTools]);
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
