import {Spinner} from "@interstate/components/Spinner";

export const ActivityIndicator = () => {
  return (
    <figure
      id={"activity-indicator"}
      data-testid={"activity-indicator"}
      className={"activity-indicator"}
      style={{
        position: "absolute",
        left: "50%",
        top: "25%"
      }}>
      <Spinner
        id={"loading-indicator-with-margin"}
        data-testid={"loading-indicator-with-margin"}
      />
    </figure>
  );
};
